import Product from "../../../shared/models/product.js";
import * as actions from "./actions";
import * as getters from "./getters";
import * as mutations from "./mutations";

const state = {
  modelClass: Product,
  baseUrl: "/api/web/member/products",
  resources: [],
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
